// ---------------------- Import font awesome matching your application config (only one icon style) ----------------------
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-pro/scss/regular.scss';
@import '@fortawesome/fontawesome-pro/scss/brands.scss';

// ---------------------- Import global css from external components ----------------------
@import 'flag-icons/css/flag-icons.css';
@import 'codemirror/lib/codemirror';
@import 'codemirror/addon/dialog/dialog';
@import 'ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import 'intl-tel-input/build/css/intlTelInput.css';

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/font/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('assets/font/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/font/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/font/roboto-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('assets/font/roboto-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('assets/font/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

:root {
  --nx3-color-theme-dark-green: #091309;
}

.btn-active {
  color: var(--nx3-color-background);
  background-color: var(--nx3-color-foreground);
  background-image: var(--nx3-color-foreground) !important;
}

.lifecycle-level {
  min-width: 90px !important;
}

.sidebar-condensed-menu {
  min-width: 210px !important;
}

.table > :not(caption) > * > select {
  background-color: white !important;
}

.smallInput {
  width: 100px;
}

typeahead-container {
  z-index: 9999 !important;
  max-height: 50vh;
}
